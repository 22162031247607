import { fetchWithOptions } from 'Utility/fetch';
import { parseJWTFromCookie } from 'Utility/jwtAuthTools';
import { makeLog } from 'Utility/logger';
import { mapSessionDataToDevices, mapSessionDataToSettings, mapSessionDataToTreatmentResponses } from './util';
import { TSessionData } from 'Routes/Treatment/Document/Form/PAPTherapy/interfaces';
import { IReadonlyDocument } from './readonlyGlobals';

/**
 * Fetch patients device session data from AVX and store session data to neuro, also updates patient link lastSync state
 */
export const syncAvxSessionData = (): Promise<boolean> => {
  return fetchWithOptions(`/api/resmed/v1/sync`, { neurojwt: parseJWTFromCookie() }, { method: 'POST' })
    .then((res) => {
      if (res.status === 200) {
        return true;
      } else throw res;
    })
    .catch((err: Error) => {
      makeLog('Error', { name: 'AVX session data sync', message: 'Response code not 200' }, err);
      return false;
    });
};

/**
 * Fetch patients device session data from neuro
 */
export const fetchAvxSessionData = (): Promise<Array<TSessionData> | null> => {
  return fetchWithOptions(`/api/resmed/v1/sessions`, { neurojwt: parseJWTFromCookie() }, { method: 'GET' })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else throw res;
    })
    .catch((err: Error) => {
      makeLog('Error', { name: 'AVX session data fetch', message: 'Response code not 200' }, err);
      return null;
    });
};

/**
 * Combined function that fetches and maps/converts AVX session data
 */
export const getAvxSessionData = (): Promise<Array<IReadonlyDocument>> => {
  return fetchAvxSessionData()
    .then((res) => {
      // Map session data to device/setting/response data used by UI
      if (Array.isArray(res) && res.length > 0) {
        return [
          ...mapSessionDataToDevices(res),
          ...mapSessionDataToSettings(res),
          ...mapSessionDataToTreatmentResponses(res),
        ];
      } else return [];
    })
    .catch(() => []);
};
